@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";
@import "../../styles/_animations.scss";

.items-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 !important;
}

.my-details {

  .name-value-box {
    .name {
      color: $gray-700;
    }

    .value {
      font-size: 18px;
    }
  }

  .reference-details {
    .name-value-box {
      padding: 40px 25px;
      background-color: #FAFAFA;
      width: 250px;
      font-weight: 500;
      line-height: 27px;

      .value {
        font-weight: 600;
      }
    }

    @include media-breakpoint-down(sm) {
      .name-value-box {
        padding: 20px 15px;
        font-weight: 500;
        line-height: 27px;
        box-sizing: border-box;
        width: 155px;
      }

      .name, .value {
        font-size: 16px;
      }

    }

  }

  .user-details {

    display: flex;
    flex-wrap: wrap;


    .name-value-box {
      background-color: $white;
      width: 30%;
      font-weight: 500;
      line-height: 27px;

      .value {
        font-weight: 600;
      }
    }

    .wrapper {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      .name-value-box {
        width: 100%;

        .name, .value {
          font-size: 16px;
        }
      }
    }

    .edit-address {
      color: $black;
      font-weight: 500;
      font-size: 16px;
      margin-left: 15px;

      .modal-toggle {
        background-color: transparent;
        text-align: left;
        max-width: 150px;
        color: $black;
        text-decoration: none;
        border-bottom: 2px solid $gray-800;
        padding-bottom: 10px;
      }

      .modal-toggle:hover {
        background-color: transparent !important;
        color: $gray-700;
      }

    }
  }

  @include media-breakpoint-down(md){
    .name-value-box {
      width: 100%;
    }
  }
}


