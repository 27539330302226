@import "../../styles/_breakpoints.scss";

.gdpr-notice {
  .gdpr-consent-checkbox {
    .form-check-label {
      font-size: 15px;
      font-weight: 400;
      display: flex;
      flex-wrap: wrap;

      p {
        width: 550px;
        @include media-breakpoint-down(sm) {
          width: 300px;
        }
        @include media-breakpoint-down(xs) {
          width: 250px;
        }
      }



      .invalid-feedback {
        width: 100%;
        order: 4;
        flex-grow: 2;
      }

    }

    .form-check-input {
      position: relative;
      margin-top: -1rem;
      margin-left: 0;
      margin-right: 1rem;

      @include media-breakpoint-down(sm) {
        margin-top: 0;
      }

    }
  }
}

