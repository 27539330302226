@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";


.messages {

  .badge {
    background-color: $greenbridge-green;
    color: $gray-200;
    border-radius: 15em;
    width: 25px;
    height: 25px;
    margin-left: 10px;
    font-size: 16px;
    vertical-align: middle;
  }

  .recent-messages {
    width: 100%;
    border-bottom: 1px solid $gray-100;
    padding: 40px 0;

    .no-recent-msg {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .email-section {
    label {
      font-family: "Roobert", sans-serif;
      font-weight: 600;
      font-size: 18px;
    }
    input, textarea {
      margin: 10px 0;
      height: 50px;
      font-family: "Roobert", sans-serif;
      font-size: 15px;
      border-radius: 0;

      &::placeholder {
        color: $gray-500;
      }
    }

    textarea {
      min-height: 300px;
    }

    button {
      height: 60px;
    }
  }
}