@import "../../styles/breakpoints";
@import "../../styles/colors";

.debug {
  position: fixed;
  color: red;
  background-color: yellow;
  width: 100%;
  bottom: 0;
  left: 10px;
  z-index: 1000;
}

.pattern-lock-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;

  .clear-pattern {
    background-color: transparent !important;
    color: $gray-700 !important;
    max-width: 100%;
    font-family: "Roobert", sans-serif;
    font-size: 18px;

    &:hover {
        background-color: transparent;
    }

    &:active {
        background-color: transparent;
    }
  }
}

.pattern-lock {
  width: 405px;
  height: 405px;

  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  position: relative;

  touch-action: none;

  @include media-breakpoint-down(sm) {
    width: 250px;
    height: 250px;

    canvas {
      position: absolute;
      width: 250px;
      height: 250px;
    }

  }

  .pattern-lock-cell {


    .pattern-lock-node-outer {
      height: 35px;
      width: 35px;
      background-color: $white;
      border: 10px solid #454545;
      border-radius: 5em;
      cursor: pointer;

      &.selected {
        border: 10px solid $greenbridge-green;
      }

      .pattern-lock-node-inner {
        height: 15px;
        width: 15px;
        background-color: $white;
        border-radius: 5em;

        &.selected {
          height: 12px;
          width: 12px;
        }
      }
    }

/*    @include media-breakpoint-down(sm) {
      padding: 15px;

      .pattern-lock-node-outer {
        height: 25px;
        width: 25px;

        .pattern-lock-node-inner {
          height: 10px;
          width: 10px;
        }
      }

    }*/
  }
}