@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";
@import "../../styles/animations";

.history {
  padding-left: 0;
  padding-right: 0 !important;
  padding-bottom: 0 !important;

  .notification {
    margin-left: 12px;
    margin-right: 12px;
  }

  @include media-breakpoint-down(sm) {
    .header {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .history-table {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }

  .history-item:nth-child(1){
    border-top: 4px solid $body-bg;
  }

  .name-value-box {
    padding: 0;

    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }

  }

  .placeholder {
    .name {
      background: $gray-400;
      width: 100px;
    }

    .value {
      background: $black;
      width: 150px;
    }
  }

}