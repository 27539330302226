@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.my-reference {
  text-align: left;
  display: inline-block;
  height: 87px;
  font-weight: 600;
  float: left;
  margin-left: 20px;
  padding: 34px 0;
  font-size: 24px;

  .reference-header {
    font-family: 'Roobert', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: $gray-700;
  }

  .wrapper {
    display: inline-block;
  }

  @include media-breakpoint-down(md) {
    margin-left: 0;
  }

  @include media-breakpoint-down(sm){
    padding: 20px 0;
    font-size: 20px;
  }
}