@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.passcode-entry {

    .btn:focus,
    .btn:active {
        outline: 0 !important;
        -webkit-appearance: none;
        box-shadow: none !important;
    }

    .modal-header {
        border: none;

        h5 {
            font-size: 26px;
        }

        p {
            font-family: "Roobert Light", sans-serif;
            font-size: 18px;
            font-weight: 100;
        }

    }

    max-width: 800px;

    .modal-content {
        padding: 60px;

        .password-label-area {
            display: block;
            font-family: "Roobert", sans-serif;
            font-size: 18px;
            line-height: 50px;

            .show-password {
                background-color: transparent !important;
                color: $gray-700 !important;
                max-width: 200px;
                font-family: "Roobert", sans-serif;
                font-size: 18px;
                float: right;

                @include media-breakpoint-down(sm) {
                    display: none !important;
                }
            }

            label {
                height: 50px;
            }
        }

        .password-submit-area {
            display: flex;
            width: 100%;
            font-family: "Roobert", sans-serif;
            font-size: 18px;
            line-height: 50px;

            .invalid-feedback {
                position: absolute;
                bottom: 10px;
            }

            input {
                height: 50px;
                font-size: 18px;
                margin: 0;
            }

            button {
                float: right;
                max-width: 190px;
                height: 50px;
            }
        }

        // smaller screens
        @include media-breakpoint-down(sm) {

            padding: 5px !important;

            .password-submit-area {
                display: block;

                button {
                    max-width: 100%;
                    float: none;
                    margin-top: 15px;
                }
            }
        }
    }

    .show-password-mobile {
        background-color: transparent !important;
        color: $gray-700 !important;
        max-width: 100%;
        font-family: "Roobert", sans-serif;
        font-size: 18px;
        display: none;

        @include media-breakpoint-down(sm) {
            display: inline-block !important;
        }

        &:hover {
            background-color: transparent;
        }

        &:active {
            background-color: transparent;
        }
    }

    .nav-item {
        border: none;
        background-color: $gray-200;
        font-family: "Roobert", sans-serif;
        font-size: 18px;
        text-align: center;
        min-width: 150px;
        width: 33%;
        border-radius: 0;
        margin-top: 2px;

        @include media-breakpoint-down(sm) {
            width: 100%
        }

        &:hover {
            background-color: $gray-300;
        }

        .active {
            border: none;
            background-color: $black;
            color: $white;

            &:hover {
                background-color: $gray-900;
                color: $white;
            }
        }
    }

    .tab-content {
        border: none;

        .active {
            padding-top: 35px;
        }
    }

    @include media-breakpoint-down(sm) {

        .modal-footer {
            margin-top: 20px !important;
            margin-bottom: 20px !important;
            padding: 0 15px;

            .security-policy {
                padding: 10px;
                font-size: 14px;
            }
        }
    }

    .modal-footer {
        border: none;
        margin-top: 50px;
        padding: 0 30px;

        .security-policy {
            padding: 15px;
            background-color: $gray-200;
            width: 100%;
            text-align: center;
            font-size: 16px;
        }
    }

}