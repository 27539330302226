@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.main-container {
    background-color: $main-bg;
    color: $main-color;
    padding: 15px 12px;
    margin: 50px auto;

    .loading-indicator {
        width: 100%;
        height: 100%;
    }

    .notification {
        margin-top: 42px;
        margin-bottom: 42px;
        background-color: #FCF5E1;
    }

    button {
        background-color: $black;
        color: $white;
        border: 0;
        border-radius: 0;
        width: 100%;
        font-family: 'Roobert', sans-serif;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
    }

    h1 {
        font-family: "Roobert SemiBold", sans-serif;
        font-size: 36px;
    }

    h5 {
        font-family: "Roobert SemiBold", sans-serif;
        font-size: 20px;
    }

    .loading-component {
        margin-bottom: 30px;
    }

}

@include media-breakpoint-down(sm) {
    .main-container {
        margin: 40px auto;
        max-width: 500px;

        .notification {
            margin-top: 20px;
        }
    }
}
