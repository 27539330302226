@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.modal-backdrop.show {
    opacity: 0.6;
}

.modal-dialog {
    max-width: 800px;
    font-family: "Roobert", sans-serif;
    font-size: 18px;
}

.styled-modal {


    .modal-content {
        padding: 50px;

        @include media-breakpoint-down(sm) {
            padding: 25px;
        }
    }

    .modal-header {
        padding: 10px;
        border: none;

        h5 {
            font-family: 'Roobert', sans-serif;
            font-size: 26px;
            font-weight: 600;
            color: $black;

        }

        @include media-breakpoint-down(sm) {
            h5 {
                font-size: 20px !important;
            }

            p {
                font-size: 16px !important;
            }
        }


        button {
            background-color: transparent;
            width: 50px;

            &:hover {
                background-color: $white !important;
            }

            span {
                color: $gray-700;
                text-shadow: none;
            }

        }
    }

    .modal-body {
        padding: 10px;
    }
    
    label {
        margin: 5px 0;
        font-family: 'Roobert SemiBold', sans-serif;
    }

    input {
        margin: 5px 0;
        border-radius: 0;
        border: 1px solid $gray-500;
        font-size: 18px;
        height: 50px;

        &::placeholder {
            color: $gray-500;
            font-family: 'Roobert Light', sans-serif;
        }

        &:focus, &.focus,
        &:active, &.active {
            background-color: transparent;
            outline: transparent none 0 !important;
            box-shadow: 0 0 0 0 rgba(0,123,255,0) !important;
            -webkit-box-shadow: none !important;
        }
    }

    button {
        background-color: $black;
        color: $white !important;
        height: 60px;
        border: 0;
        border-radius: 0;
        width: 100%;
    }

    button:hover {
        opacity: .6;
        background-color: $black;
    }
}
