@import "../../styles/_breakpoints.scss";

.navbar-brand {
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url('/img/greenbridge-icon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: center;
    background-clip: padding-box;

    max-height: 51px;

    // Use this instead of the above if you want a seperate logo when the sidebar is collapsed.
    // NOTE you'll also want to uncomment the two inner divs in AppNavbarBrand.tsx.
    /*.navbar-brand-full {
        width: 100%;
        height: 100%;
        background-image: url('/img/logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
    }

    .navbar-brand-minimized {
        width: 100%;
        height: 100%;
        background-image: url('/img/logo-minimized.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
    }*/
}

