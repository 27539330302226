@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";


.notification {
  padding: 20px;
  background-color: #FCF5E1;
  font-size: 18px;
  font-weight: 500;
  font-family: "Roobert Medium", sans-serif;
  display: flex;
  flex-wrap: wrap;

  &.persistent {
    background-color: $greenbridge-blue-light;
  }

  .notification-message {
    background-color: transparent;
    border: 0;
  }

  .link {
    margin-left: 15px;
    font-family: "Roobert", sans-serif;
  }

  a {
    color: $black;
    border-bottom: 2px solid #F6C203;
  }

  a:hover {
    text-decoration: none;
    font-weight: 600;
  }

  @include  media-breakpoint-down(sm){
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
    text-align: center;

    .notification-message, .link {
      font-size: 15px;
      width: 100%;
    }

    .link {
      margin-left: 0;
    }

  }
}
