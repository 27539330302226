@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.report {
  font-family: "Roobert", sans-serif;
  font-size: 15px;
  font-weight: 500;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }

  table {

    th {
      width: 145px;
      color: #5B5B5B;
    }

    th td {
      padding: 0.55rem;
      font-weight: 500;
    }
  }

  .report-section {
    padding: 20px;
  }

  .alternative {
    background-color: #FAFAFA;
  }

  .button-container {
    button {
      width: 326px;
      height: 50px;
      margin: 0 auto;
      display: block;
    }
  }

}