@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.icloud-entry {

    margin-top: 100px;
    max-width: 650px;

    .btn:focus,
    .btn:active {
        outline: 0 !important;
        -webkit-appearance: none;
        box-shadow: none !important;
    }

    .modal-header {
        border: none;

        h5 {
            font-size: 26px;
        }

        p {
            font-family: "Roobert Light", sans-serif;
            font-size: 18px;
            font-weight: 100;
        }
    }

    .modal-content {
        padding: 40px;

        .button-container {

            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            button {
                margin: 10px;
                width: 100%;
                max-width: 250px;
                height: 50px;
            }

            .btn-secondary {
                background-color: $gray-700;

                &:hover {
                    background-color: $greenbridge-green;
                }
            }
        }
    }

    .modal-footer {
        border: none;
        margin-top: 50px;
        padding: 0 30px;

    }
}