@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.photos {

  .inspection-date {
    font-family: "Roobert", sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;

  }

  .photo-gallery {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .download-section {
    text-align: center;

    button {
      width: 300px;
      height: 60px;
      margin: 0 auto;
    }
  }

}