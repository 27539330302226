@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";


.progress-status {
  min-width: 110px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 5em;
  font-weight: 600;
  background-color: $greenbridge-gold-light;
  color: $secondary;
  display: flex;
  align-content: center;
  justify-content: center;

  .wrapper {
    margin-top: 4px;
  }

  @include media-breakpoint-down(md){
    margin-left: 0;
  }
    // fa icon
  svg {
    margin-right: 8px;
  }
}

.loading {
  .progress-status{
      //background: linear-gradient(270deg, $greenbridge-green-light, $greenbridge-green);
      //background-size: 400% 400%;
      //
      //-webkit-animation: placeholderAnimation 3s ease infinite;
      //-moz-animation: placeholderAnimation 3s ease infinite;
      //animation: placeholderAnimation 3s ease infinite;

    .wrapper {
      display: none;
    }

    svg {
      display: none;
    }
  }

  .loading-indicator {
    margin-top: 3px;
    margin-left: 10px;
  }
}


.on-hold {
  background-color: #faf5e6;
  color: $secondary;
}

.in-progress {
  background-color: $greenbridge-green-light;
  color: $greenbridge-green;
}

.finished {
  background-color: $greenbridge-blue-light;
  color: $greenbridge-blue;
}
//
//.sm {
//
//}
//
//.lg {
//
//}