﻿// Colors
//
// These colours can be included by any scss files for components if required.
// They also get included in in the main imports for bootstrap and coreui by the layout to override their colours.

// Main colours for the app's theme.
$primary: #67BEB0;
$secondary: #eec443;
$success: #00B79B;
$info: #63c2de;
$warning: #ffc107;
$danger: #f86c6b;
$light: #f0f3f5;
$dark: #2f353a;

// Greenbridge brand colours:
$greenbridge-green: #00B79B;
$greenbridge-blue: #2ec6d6;
$greenbridge-gold: #EEC39C;
$greenbridge-teal: #BCD9DF;

$greenbridge-gold-light: #faf5e6;
$greenbridge-green-light: #bfe3dd;
$greenbridge-blue-light: #e2eff1;

// Useful monochrome colours.
$white: #fff;
$black: #000;
$gray-base: #181b1e;
$gray-100: #f0f3f5; // lighten($gray-base, 85%);
$gray-200: #e4e7ea; // lighten($gray-base, 80%);
$gray-300: #c8ced3; // lighten($gray-base, 70%);
$gray-400: #acb4bc; // lighten($gray-base, 60%);
$gray-500: #c1bebe; // lighten($gray-base, 50%);
$gray-600: #73818f; // lighten($gray-base, 40%);
$gray-700: #5c6873; // lighten($gray-base, 30%);
$gray-800: #2f353a; // lighten($gray-base, 10%);
$gray-900: #23282c; // lighten($gray-base, 5%);


// Explict light and dark pairs.
$dark-bg: $gray-900;
$dark-color: $white;
$light-bg: $white;
$light-color: $gray-800;

// Colours pairs for main areas.
$body-bg: #F5F9FC;
$body-color: $gray-900;
$main-bg: $white;
$main-color: $gray-900;
