@import "../../styles/breakpoints";
@import "../../styles/colors";

.pin-entry {

    .pin-submit-area {
        display: flex;
        width: 100%;
        font-family: "Roobert", sans-serif;
        font-size: 28px;
        line-height: 50px;

        @include media-breakpoint-down(sm) {
            border: 1px solid $gray-300;
        }


        .invalid-feedback {
            position: absolute;
            bottom: 10px;
        }

        input {
            text-align: center;
            border: none;
            border-radius: 0;
            background-color: $gray-300;
            height: 50px;
            font-size: 18px;
            margin: 5px;

            @include media-breakpoint-down(sm) {
                margin: 0;
                font-size: 15px;
                background-clip: border-box;
                background-color: transparent;
            }
        }

        input:focus,
        input:active {
            outline: 0px !important;
            -webkit-appearance: none;
            box-shadow: none !important;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }


        &:hover {
            input {
                height: 50px;
                font-size: 18px;
                background-color: $gray-400;
            }
        }
    }

    .submit-button {
        margin-top: 25px;
        width: 100%;
        height: 50px;
    }
}