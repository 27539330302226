@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.claim-current-state-summary {
  float: left;
  margin: auto;
  text-align: left;

  .loading-wrapper {

    min-width: 200px;
    height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .loading-indicator {
      margin-left: 10px;
      height: 46px;
    }
  }

  p {
    margin-bottom: 0;
    font-family: "Roobert Light", sans-serif;
    font-size: 14px;
  }

  .current-state {
    font-family: 'Roobert Medium', sans-serif;
    font-size: 64px;
    font-weight: 600;

    @include media-breakpoint-down(lg){
      font-size: 54px;
    }


    @include media-breakpoint-down(md){
      font-size: 46px;
      padding: 5px 0;
      min-height: 100px;
    }
  }
}