@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.claim-next-stage-summary {
  background-color: $greenbridge-green;
  color: $white;
  padding: 25px 30px;
  height: 110px;
  max-width: 350px;
  float: right;
  font-family: "Roobert", sans-serif;

  .header {
    font-size: 16px;
  }

  .value {
    font-size: 24px;
    font-weight: 600;
  }

  .next-stage {
    margin-left: 8px;
  }


  @include media-breakpoint-down(md){
    text-align: left;
    max-width: 350px;
    float: left;
    margin: 15px 0;

    .header {
      font-size: 14px;
    }

    .value {
      font-size: 20px;

    }

  }
}
