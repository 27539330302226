@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";


.login-area {

    padding-top: 50px;
    width: 100%;
    padding-left: 100px;

    .login-container {

        background-color: $main-bg;
        color: $main-color;
        margin: 0;
        padding: 75px 50px 35px 50px;
        width: 612px;
        max-width: 650px;


        .form-group {
            margin: 40px 0;
        }

        .form-buttons {
            margin: 40px 0;
        }

        input {
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid $gray-500;
            font-size: 22px;
            padding-left: 0;

            &::placeholder {
                color: $gray-500;
                font-family: 'Roobert Light', sans-serif;
                font-size: 22px;
            }

            &:focus, &.focus,
            &:active, &.active {
                background-color: transparent;
                outline: transparent none 0 !important;
                box-shadow: 0 0 0 0 rgba(0,123,255,0) !important;
                -webkit-box-shadow: none !important;
            }
        }
    
        label {
            font-family: 'Roobert SemiBold', sans-serif;
            font-size: 16px;
        }
    
        button {
            background-color: $black;
            color: $white;
            border: 0;
            border-radius: 0;
            width: 100%;
            height: 60px;
            font-size: 16px;
            font-weight: 600;

            &:hover {
                background-color: $greenbridge-green;
            }
        }


        @include media-breakpoint-down(sm) {

            padding: 75px 25px 35px 25px;

            input {
                font-size: 16px;

                &::placeholder {
                    font-size: 16px;
                }
            }

            label {
                font-size: 16px;
            }

            button {
                height: 50px;
            }
        }


    }

    .footer-container {
        margin-top: 50px;
        background-color: transparent;
        width: 100%;
        text-align: center;
    
        .footer {
            background-color: $white;
            width: 100%;
            font-size: 15px;
            color: $gray-700;

            .list-group-item {
                background-color: transparent;
                border: none;
                color: $gray-700;
                padding: 10px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding-left: 50px;
        height: 100%;
    }
    
    @include media-breakpoint-down(sm) {
        max-width: 100%;
        width: 100%;
        padding: 0;
        margin-right: 0;
        margin-left: 0;

        .login-container {
            margin: 0 !important;
            padding-top: 20px;
            width: 100%;
            max-width: 100%;
            height: 100%;

            .banner {
                margin-bottom: 40px;
            }

        }

        .footer-container {
            max-width: 100%;
            width: 100%;

            .list-group-item {
                padding: 10px;
            }

        }
    }
    
}

