﻿@import "./_breakpoints.scss";
@import "./_colors.scss";


@-webkit-keyframes placeholderAnimation {
  0%{background-position:0 50%}
  50%{background-position:100% 50%}
  100%{background-position:0 50%}
}
@-moz-keyframes placeholderAnimation {
  0%{background-position:0 50%}
  50%{background-position:100% 50%}
  100%{background-position:0 50%}
}
@keyframes placeholderAnimation {
  0%{background-position:0 50%}
  50%{background-position:100% 50%}
  100%{background-position:0 50%}
}


@mixin transition($n-children) {
  @for $i from 1 through $n-children {
    &:nth-child(#{$i}) {
      transition: all .2s #{$i * .1}s ease-in;
    }
  }
}

