@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";
@import "../../styles/animations";


.history-item {
  outline: 4px solid $body-bg;
  height: 80px;
  width: 100%;

  .history-text {
    margin-left: 17px;
    font-weight: 500;
    font-size: 18px;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }


  td {
    padding: 15px 25px;
  }

  td:nth-child(2){
    text-align: right;
    width: 200px;
  }

  .date-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .loading-indicator {
      vertical-align: middle;
      display: table-cell;
      left: 10px;
      width: 40px;
      height: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    td {
      padding: 10px 15px;
    }

    td:nth-child(2) {
      width: 100px;
    }
  }

}


.history .placeholder {
  .history-text {
    height: 10px;
    background: $gray-700;
    margin-left: 20px;
    animation: unset;

    @include media-breakpoint-down(sm) {
      height: 5px;
    }

  }
}

//iterate through placeholder children and give them random widths
@for $i from 1 through 10 {
  .history .placeholder:nth-child(#{$i}) {

    .history-text {
      width: percentage((random(34) + 15) / 100);
      opacity: $i;
    }
  }
}

