@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";
@import "../../styles/animations";

.quote {
  .quote-notification {
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    background-color: $greenbridge-green-light;
    font-size: 16px;
    font-family: "Roobert Medium", sans-serif;
    margin-left: 30px;
    margin-right: 30px;

    span {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }

  .table {
    font-family: "Roobert Light", sans-serif;
    font-size: 16px;

    td {
      border: 5px solid $white;
    }

    th {
      border: 5px solid $white;
    }

    thead th {
      margin-bottom: 20px;
      background-color: $gray-100;
      color: $gray-700;
      font-family: "Roobert Light", sans-serif;
      font-size: 16px;
      border: 5px solid $white;
      box-sizing: border-box;
    }

    .totals th td {
      margin-top: 20px;
    }

    .totals td, th {
      color: $black;
    }

    .totals th, .totals td {
      background-color: $gray-100;
      color: $gray-700;
      font-family: "Roobert Light", sans-serif;
      font-size: 16px;
      font-weight: 500;
      border: 5px solid $white;
      box-sizing: border-box;
    }
  }

}