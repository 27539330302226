@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";


.responsive-progress {

  .progress-bg {
    background-color: $gray-100;
    border-radius: 10.8px;
  }

  .progress-bar {
    height: 9px;
    border-radius: 10.8px;
    background-color: $greenbridge-green;
  }

  .progress-bg-vertical {
    background-color: $gray-100;
    border-radius: 10.8px;
    width: 6px;
    margin-right: 10px;
    height: 100px;
  }

  .progress-bar-vertical {
    border-radius: 10.8px;
    background-color: $greenbridge-green;
    width: 6px;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
    display:block;
  }

}