@import "../../styles/breakpoints";
@import "../../styles/colors";

.pattern-entry {

  .password-submit-area {

    display: block;
    width: 100%;
    font-family: "Roobert", sans-serif;
    font-size: 28px;
    line-height: 50px;

    .invalid-feedback {
      position: absolute;
      bottom: 10px;
    }

    input {
      text-align: center;
      border: none;
      border-radius: 0;
      background-color: $gray-200;
      height: 50px;
      font-size: 18px;
      margin: 5px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


    &:hover {
      input {
        height: 50px;
        font-size: 18px;
        background-color: $gray-400;
      }
    }
  }

  .submit-button {
    margin-top: 25px;
    width: 100%;
    height: 50px;
  }
}