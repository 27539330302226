@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";


.my-delivery {


    .image-wrapper {
        width: 100%;
        height: 50px;

        .courier-image {
            margin-top: 10px;
            max-height: 40px;
            max-width: 100%;
            position: relative;
            float: left;
        }
    }

    .delivery-header {
        width: 100%;
        position: relative;
        display: inline-flex;
        margin-bottom: 10px;

        .title {
            float: left;
        }

        .courier-link-wrapper {
            float: right;

            .courier-link {
                width: 250px;
                height: 50px;
                float: right;
                margin-bottom: 20px;
            }
        }

        @include media-breakpoint-down(md) {

            display: grid;

            .courier-link-wrapper {
                margin-top: 15px;
                position: relative;
                max-width: 100%;
                display: inline;

                .courier-link {
                    width: 100%;
                }
            }
        }
    }

    .delivery-summary {
        display: flex;
        flex-wrap: wrap;

        .alert {
            width: 100%;
            max-height: 55px;
        }

        .name-value-box {
            padding: 25px;
            width: 330px;
            background-color: #FAFAFA;

            @include media-breakpoint-down(md){
                width: 100%;
            }
            
            .name {
                color: $gray-700;
                font-weight: 500;
                font-size: 18px;
            }

            .value {
                margin-top: 10px;
                color: $black;
                font-size: 18px;
                font-weight: 500;
            }

        }
        
    }

    .status-table {
        width: 100%;
        font-size: 16px;
        font-family: "Roobert", sans-serif;
        margin-left: 10px;
        margin-right: 10px;

        thead {
            .header-row {
                border-top: 1px solid $gray-300;
                border-bottom: 1px solid $gray-300;
                padding: 5px;
                color: $gray-400;
                font-weight: 500 !important;
            }
        }
        
        tr {
            color: $black;
            padding: 5px;
        }

        tr:nth-child(2) {
            background-color: $gray-100;
        }

    }
}
