@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";
@import "../../styles/animations";

.my-claim {

  .claim-status {
    margin-top: 75px;
    margin-bottom: 50px;
  }

  .device-container {
    padding: 0 !important;
    margin: 0;
  }

  .device-summary {
    display: flex;
    margin: 0 0 40px 0;
    padding: 0;
    flex-wrap: wrap;

    .name-value-box {

      margin-left: 15px;

      &:first-child {
        margin-left: 0;
        padding-left: 0;
      }

      .name {
        font-size: 15px;
      }
    }

    @include media-breakpoint-down(sm) {


      .name-value-box {
        width: 100%;
        padding-left: 0;

        .value {
          font-size: 16px;
        }
        .name {
          font-size: 18px;
        }
      }
    }
  }

  .no-data-message {
    width: 100%;
    font-family: "Roobert", sans-serif;
    font-size: 16px;
    text-align: left;
    font-weight: 500;
    background-color: $white;
    padding: 30px;
    margin: 20px;
  }

  .device-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: $gray-100;
    padding: 65px 80px;
    width: auto;

    .name-value-box {
      padding: 20px 30px;
      width: 250px;
      border-left: 3px solid #1C85E8;

      .name {
        font-size: 15px;
      }

      .value {
        font-size: 17px;
        font-weight: 600;
      }
    }

    @include media-breakpoint-down(md){
      padding: 33px 40px;
    }

    @include media-breakpoint-down(sm){

      padding: 15px 20px;

      .name-value-box {
        width: 100%;

        .name {
          font-size: 15px;
        }

        .value {
          font-size: 16px;
        }
      }
    }
  }

}
