@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.page-section {
  width: auto;
  box-sizing: border-box;
  margin: 60px 42px;

  h1 {
    font-size: 42px;
    font-weight: 600;
  }

  @include media-breakpoint-down(sm) {
    margin: 20px 10px;

    h1 {
      font-size: 25px;
      font-weight: 600;
    }
  }
}