@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";


.claim-status {

  .progress-step {
    @include media-breakpoint-down(md){
      display: flex;
      margin: 5px;

      .progress-details {
        margin: 15px 15px;
      }
    }

    .progress-bg-vertical {
      height: 80px;
    }
  }

  .button-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    align-content: flex-start;
    width: 100%;
    padding: 15px;

    button {
      margin: 0 5px;
      height: 50px;
      padding: 15px 20px;
      max-width: 300px;
      letter-spacing: 0.8px;

      @include media-breakpoint-down(sm) {
        margin: 5px;
        max-width: 100%;
        flex: 0 1 100%;
      }
    }
  }

  .placeholder {
    .state {
      height: 6px !important;
      width: 100px;
      background: $black;
      background-size: 400% 400%;
      -webkit-animation: unset;
      -moz-animation: unset;
      animation: unset;
    }

    .description {
      height: 8px !important;
      width: 150px;
      background: $gray-400;
      background-size: 400% 400%;
      -webkit-animation: unset;
      -moz-animation: unset;
      animation: unset;
    }
  }


  .progress-details {
    margin: 25px 5px;
    display: flex;

    .progress-description {
      height: 43px;
      margin-left: 10px;

      .state {
        letter-spacing: -0.15px;
        line-height: 20px;
        font-family: 'Roobert', sans-serif;
        font-size: 17px;
        height: 20px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .description {
        color: #8e92a4;
        height: 18px;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.14px;
        line-height: 18px;
        font-family: 'Roobert', sans-serif;
      }
    }
  }
}

