@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";
@import "../../styles/_animations.scss";

.image-container {
  width: 320px;
  max-height: 255px;
  margin: 10px;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .2s ease;
    background-color: rgba(255, 255, 255, 0);
  }

  .overlay:hover{
    background-color: rgba(255, 255, 255, 0.2);
    button {
      display: block;
    }
  }

  .image {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  .placeholder {
    height: 255px;
    display: flex;
    align-content: center;
    padding: 20px;
    flex-direction: column;

    background: linear-gradient(270deg, $gray-100, #fafafa);
    background-size: 400% 400%;

    -webkit-animation: placeholderAnimation 3s ease infinite;
    -moz-animation: placeholderAnimation 3s ease infinite;
    animation: placeholderAnimation 3s ease infinite;

    .loading-indicator {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }

    .shape1 {
      height: 80px;
      width: 80px;
      border-radius: 15em;
      background-color: $gray-200;
      align-self: center;
      margin: 20px;
    }

    .shape2 {
      height: 50px;
      width: 100%;
      background-color: $gray-200;
      align-self: center;
      margin: 20px;
    }

  }

  button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 15em;
    display: none;
  }

  @include media-breakpoint-down(md){
    width: 100%;
  }
}

.image-modal {

  margin-top: 100px;

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;

    button {
      width: 220px;

      svg {
        margin-left: 10px;
      }
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      justify-content: center;

      button {
        width: 100%;
      }
    }
  }

  .modal-content {
    padding: 0 !important;
  }

  .modal-body {
    padding: 0;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  .modal-image-container {
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}