@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.header-container {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  margin-left: 0;
  box-shadow: 25px 6px 30px rgba(219,227,238,0.2);

  @include media-breakpoint-down(lg){
    padding: 0;
  }

  .app-header {
    background-color: $white;
    position: relative;
    width: 100%;
    margin: auto;
    height: auto;
    font-family: 'Roobert', sans-serif;
    border-bottom: 0;
    min-height: 100px;
    // box-shadow: 0 2px 3px $gray-300;


    .navbar-brand {
      position: relative;
      margin-top: 20px;
      float: left;
    }

    @include media-breakpoint-down (md) {

      .header-contents {
        max-width: 100%;
      }

      .navbar-brand {
        margin-left: 20px;
        left: 0;
      }
    }

    @include media-breakpoint-down (sm) {

      .navbar-brand {
        max-height: 30px;
        left: 0;
        margin-left: 0;
      }
    }


    .menu-nav {
      width: 100%;
      height: 70px;

      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      display: list-item;

      // Hide scrollbar in IE and FF
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

    }

    // Hide scrollbar in chromium
    .menu-nav::-webkit-scrollbar {
      display: none;
    }

    // navigation
    .nav-item {
      display: inline-block;
      text-align: center;
      height: 70px;


      &:hover {
        border-bottom: 3px solid $greenbridge-blue;
      }

      .nav-link {
        display: inline-block;
        height: 70px;
        padding: 16px 20px;
        color: $gray-600;
        font-size: 16px;
        font-weight: 500;
      }

      @include media-breakpoint-down(sm) {

        .nav-link {
          font-size: 15px;
        }
      }

      .active {
        font-family: 'Roobert SemiBold', sans-serif;
        border-bottom: 3px solid $greenbridge-blue;
        color: $black;
        font-weight: 600;
      }
    }


  }

}

