@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";
@import "../../styles/animations";

.name-value-box {
  background-color: $white;
  margin: 5px;
  padding: 10px;
  font-family: "Roobert", sans-serif;
  font-weight: 500;
}

.inline {
  display: flex;
  font-size: 18px;
  font-weight: 600;

  .name {
    color: $black;
  }

  .value {
    margin-left: 10px;
    color: $gray-500;
  }

}

.placeholder {

  p {
    background: $gray-400;
    background-size: 400% 400%;
  }

  .name {
    height: 7px;
    color: $gray-700;
  }

  .value {
    height: 6px;
    background: $gray-500;
  }
}


.sm {
  .name {
    color: $gray-700;
    font-size: 15px;
    font-family: "Roobert", sans-serif;
    font-weight: 500;
  }

  .value {
    font-family: "Roobert", sans-serif;
    font-weight: 500;
    font-size: 17px;
  }
}


.md {
  .name {
    color: $gray-700;
    font-size: 17px;
    font-family: "Roobert", sans-serif;
    font-weight: 400;
  }

  .value {
    font-family: "Roobert", sans-serif;
    font-weight: 600;
    font-size: 24px;
  }
}
