@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";


.loading-component {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;

  display: flex;

  .loading-indicator {
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    flex: 0 1 100px;

    @include media-breakpoint-down(sm) {
      height: 30px;
    }

  }
}

