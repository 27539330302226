@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.summary {
  padding: 120px 0;

  @include media-breakpoint-down(md){
    padding: 50px 35px;
  }

  @include media-breakpoint-down(sm){
    padding: 30px 5px;
  }

  .progress-status {
    float: left;
  }
}

// styles for placeholder components while loading
.loading {

  .loading-indicator {
    width: 4px;
    height: 4px;
    position: relative;
    left: 1px;

    .spinner-grow {
      display: block;
    }

  }

  .claim-next-stage-summary {
    .loading-indicator {
      position: relative;
      left: 11px;
      top: 5px;
    }
  }

  .my-reference {
    .loading-indicator {
      position: relative;
      left: 11px;
      top: 5px;
    }
  }

}