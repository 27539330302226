@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";


.banner {
    width: 100%;
    color: $black;

    h1 {
        font-family: 'Roobert SemiBold', sans-serif;
        font-size: 53px;
        font-weight: 600;

        @include media-breakpoint-down(md) {
            font-size: 42px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 32px;
        }
    }

    .container {
        padding: 0;
        margin-left: 0;
    }

    .sticky-toolbar.sticky-toolbar-stuck {
        color: $black;
    }
    
    // If we are using our full-height variant, then make sure we fill the available screen area.
    &.banner-full-height {
        height: auto;
        min-height: 100%;
    }
}

// Padding and sizes on different screen sizes.
.banner {
    padding-bottom: 20px;
}

@include media-breakpoint-up(sm) {
    .banner {
        padding-bottom: 20px;
    }
}

@include media-breakpoint-up(md) {
    .banner {
        padding-bottom: 20px;
    }
}