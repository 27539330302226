@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.date-view {
  height: 43px;
  width: 23px;
  margin-right: 15px;

  .no-data-placeholder {
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: $gray-800;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
  }

  .month {
    font-size: 15px;
    font-family: "Roobert", sans-serif;
    font-weight: 500;
    letter-spacing: -0.14px;
    line-height: 18px;
    text-align: center;

  }

  .day {
    font-size: 20px;
    font-family: "Roobert", sans-serif;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 24px;
    text-align: center;
    height: 24px;
  }
}

.date-view.sm {

  height: auto;
  width: auto;
  margin: 5px;

  .month {
    font-size: 15px;
    font-family: "Roobert", sans-serif;
    font-weight: 600;
    letter-spacing: -0.14px;
    line-height: 18px;
    text-align: center;
    display: inline-block;
    padding-right: 5px;
  }

  .day {
    font-size: 15px;
    font-family: "Roobert", sans-serif;
    font-weight: 600;
    letter-spacing: -0.18px;
    line-height: 24px;
    text-align: center;
    height: 24px;
    display: inline-block;

  }

  .time {
    margin-left: 15px;
    font-weight: 500;
    font-size: 15px;
    color: #858585;
    display: inline-block;
  }


  @include media-breakpoint-down(sm) {
    display: flex;
    flex-wrap: wrap;
    max-width: 50px;

    .time {
      margin-left: 0;
    }

    .day, .month, .time {
      font-size: 12px;
      line-height: normal;
    }
  }

}