@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";


.message {
  width: 100%;
  padding: 15px;
  display: inline-flex;
  background-color: #fafafa;
  border-bottom: 1px solid $gray-200;
  font-size: 14px;
  position: relative;
  overflow: hidden;

  &.unread {
    background: rgb(250,250,250);
    background: linear-gradient(90deg, rgb(250, 250, 250) 94%, rgb(217, 237, 234) 100%);
  }

  &:hover {
    cursor: pointer;
    background-color: $gray-100;
  }

  &.unread:hover {
    background: rgb(250,250,250);
    background: linear-gradient(90deg, rgba(240, 243, 245, 1) 94%, rgba(217,237,234,1) 100%);
  }


  .message-subject {
    width: 20%;
    height: 100%;
    background-color: $gray-200;
    padding: 10px;
    font-weight: 600;
    text-align: center;
    margin: auto 0;

    @include media-breakpoint-down(sm){
      width: 30%;
      font-size: 14px;
      text-align: left;
    }
  }

  .message-preview {
    width: 70%;
    padding: 10px;
    color: $gray-600;

    @include media-breakpoint-down(sm){
      width: 60%;
      font-size: 14px;
    }
  }

  .button-container {
    width: 10%;

    .modal-button {
      width: 15px;
      height: 100%;
      color: $gray-700;
      background-color: transparent;
      float: right;

      &:focus, &.focus,
      &:active, &.active {
        background-color: transparent;
        outline: transparent none 0 !important;
        box-shadow: 0 0 0 0 rgba(0,123,255,0) !important;
        -webkit-box-shadow: none !important;
      }
    }
  }

  &:nth-last-child(1) {
    border-bottom: none;
  }
}

.message-modal {

  .modal-content {
    padding: 40px;
  }

}